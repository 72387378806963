<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Chapel Creditor</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            {{ termText }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="{ value, text } of terms" :key="value" :disabled="term === value" @click="term = value">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
        <v-select v-model="chapel" :items="chapels" label="Choose Chapel (most recent 10 chapels shown)" outlined class="mt-4" item-value="_id" item-text="date">
          <template v-slot:item="{ item: { date, title, speaker } }">
            <v-list-item-content>
              <v-list-item-title>{{ date }}</v-list-item-title>
              <v-list-item-subtitle v-if="speaker">{{ speaker }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="title">{{ title }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
        <directory-search v-if="chapel" ref="directorySearch" v-model="student" :filter="{ pidm: { $in: noCreditPidms } }" :person-filter="['Student']" value-field="pidm" search-label="Student Search" include-photo></directory-search>
        <v-list v-if="studentList.length > 0">
          <v-list-item v-for="{ avatar, text, subtext, pidm, status } in studentList" :key="pidm">
            <v-list-item-avatar>
              <v-img :src="avatar || '/img/no.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
              <v-list-item-subtitle>{{ subtext }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-subtitle v-if="status === 'P'">
                Adding credit...
                <v-icon color="info">fal fa-spinner fa-spin</v-icon>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else-if="status === 'Y'">
                Credit Added
                <v-icon color="success">fal fa-check-circle</v-icon>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else-if="status === 'A'">
                Already has credit
                <v-icon color="info">fal fa-check-circle</v-icon>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else-if="status === 'X'">
                No Chapel Record Found
                <v-icon color="error">fas fa-exclamation-circle</v-icon>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else-if="status === 'R'">
                Credit Removed
                <v-icon color="error">fal fa-minus-circle</v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="status === 'Y' || status === 'A' || status === 'R'">
              <v-btn v-if="status === 'Y' || status === 'A'" icon @click="removeCredit(pidm)">
                <v-icon color="error">fal fa-times-circle</v-icon>
              </v-btn>
              <v-btn v-else-if="status === 'R'" icon @click="addCredit(pidm)">
                <v-icon color="success">fal fa-plus-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { ref, onMounted, watch, computed } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  components: {
    DirectorySearch: () => import('../../components/greatscots/searchField')
  },
  setup (props, { root }) {
    const term = ref('')
    const termText = computed(() => {
      const rec = terms.value.filter(({ value }) => value === term.value)
      if (rec.length === 1) return rec[0].text
      return term.value
    })
    const terms = ref([])
    const chapel = ref('')
    const chapels = ref([])
    const creditType = ref('Sign-in')
    const creditValue = ref(1)

    watch(term, () => loadChapels())

    async function loadChapels () {
      if (term.value != null && term.value !== '') {
        const query = { date: { $lte: new Date() }, term: term.value, $limit: 10, $sort: { date: -1 }, $select: ['_id', 'date', 'speaker', 'title', 'term'] }
        const { data } = await root.$feathers.service('chapel/schedule').find({ query })
        chapels.value = data.map((row) => { return { ...row, date: stringFormatDate(row.date) } })
      }
    }

    onMounted(async () => {
      // Load term options (current and previous terms only)
      const endWindow = new Date()
      endWindow.setDate(endWindow.getDate() + 5)
      const startWindow = new Date()
      startWindow.setDate(startWindow.getDate() - 90)
      const { data } = await root.$feathers.service('system/term').find({ query: { start: { $lte: endWindow }, end: { $gte: startWindow }, type: 'Traditional', $sort: { start: -1 } } })
      terms.value = data.map(({ term, label }) => { return { text: label, value: term } })
      term.value = data[0].term
    })

    const noCreditPidms = ref([])
    watch(chapel, (id) => {
      if (id != null && id !== '') {
        root.$feathers.service('chapel/schedule').get(id).then(({ credits }) => {
          if (credits && creditType.value in credits) creditValue.value = credits[creditType.value]
          else creditValue.value = 1
        })
        const query = { aggregate: [
          { $match: { term: term.value, 'credits.chapel': { $ne: chapel.value } } },
          { $project: { pidm: 1 } }
        ] }
        root.$feathers.service('chapel/student').find({ query }).then(({ data }) => {
          noCreditPidms.value = data.map(({ pidm }) => pidm)
        })
      }
    })

    const directorySearch = ref(null)
    const student = ref(null)
    const studentList = ref([])
    watch(student, () => {
      if (student.value != null && 'value' in student.value && student.value.value !== '') {
        const pidm = student.value.value
        studentList.value.splice(1, 0, { ...student.value, pidm, status: 'P' })
        for (let i = 0; i < noCreditPidms.value.length; i++) {
          if (noCreditPidms.value[i] === pidm) {
            noCreditPidms.value.splice(i, 1)
            break
          }
        }
        addCredit(student.value.value)
        directorySearch.value.clear()
      }
    })
    function addCredit (pidm) {
      root.$feathers.service('chapel/student').find({ query: { pidm, term: term.value } }).then(async ({ data }) => {
        let status = 'X'
        if (data.length === 1 && data[0].credits.filter(({ chapel: credChap }) => credChap === chapel.value).length === 0) {
          await root.$feathers.service('chapel/student').patch(data[0]._id, { $push: { credits: {
            credit: creditValue.value,
            chapel: chapel.value,
            date: new Date(),
            type: creditType.value
          } } })
          status = 'Y'
        } else if (data.length === 1) {
          status = 'A'
        }
        for (let i = 0; i < studentList.value.length; i++) {
          if (studentList.value[i].pidm === pidm) {
            studentList.value.splice(i, 1, { ...studentList.value[i], status })
            break
          }
        }
      })
    }

    async function removeCredit (pidm) {
      const { data } = await root.$feathers.service('chapel/student').find({ query: { pidm, term: term.value } })
      if (data.length === 1) {
        const { _id, credits } = data[0]
        await root.$feathers.service('chapel/student').patch(_id, { credits: credits.filter(({ chapel: credChap }) => credChap !== chapel.value) })
        for (let i = 0; i < studentList.value.length; i++) {
          if (studentList.value[i].pidm === pidm) {
            studentList.value.splice(i, 1, { ...studentList.value[i], status: 'R' })
            break
          }
        }
      }
    }

    return {
      term,
      termText,
      terms,
      chapel,
      chapels,
      creditType,
      creditValue,
      noCreditPidms,
      directorySearch,
      student,
      studentList,
      addCredit,
      removeCredit
    }
  }
}
</script>
