var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Chapel Creditor")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.termText)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.terms),function(ref){
var value = ref.value;
var text = ref.text;
return _c('v-list-item',{key:value,attrs:{"disabled":_vm.term === value},on:{"click":function($event){_vm.term = value}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"2","lg":"6","offset-lg":"3"}},[_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.chapels,"label":"Choose Chapel (most recent 10 chapels shown)","outlined":"","item-value":"_id","item-text":"date"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var ref_item = ref.item;
var date = ref_item.date;
var title = ref_item.title;
var speaker = ref_item.speaker;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(date))]),(speaker)?_c('v-list-item-subtitle',[_vm._v(_vm._s(speaker))]):_vm._e(),(title)?_c('v-list-item-subtitle',[_vm._v(_vm._s(title))]):_vm._e()],1)]}}]),model:{value:(_vm.chapel),callback:function ($$v) {_vm.chapel=$$v},expression:"chapel"}}),(_vm.chapel)?_c('directory-search',{ref:"directorySearch",attrs:{"filter":{ pidm: { $in: _vm.noCreditPidms } },"person-filter":['Student'],"value-field":"pidm","search-label":"Student Search","include-photo":""},model:{value:(_vm.student),callback:function ($$v) {_vm.student=$$v},expression:"student"}}):_vm._e(),(_vm.studentList.length > 0)?_c('v-list',_vm._l((_vm.studentList),function(ref){
var avatar = ref.avatar;
var text = ref.text;
var subtext = ref.subtext;
var pidm = ref.pidm;
var status = ref.status;
return _c('v-list-item',{key:pidm},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":avatar || '/img/no.jpg'}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(subtext))])],1),_c('v-list-item-content',[(status === 'P')?_c('v-list-item-subtitle',[_vm._v(" Adding credit... "),_c('v-icon',{attrs:{"color":"info"}},[_vm._v("fal fa-spinner fa-spin")])],1):(status === 'Y')?_c('v-list-item-subtitle',[_vm._v(" Credit Added "),_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fal fa-check-circle")])],1):(status === 'A')?_c('v-list-item-subtitle',[_vm._v(" Already has credit "),_c('v-icon',{attrs:{"color":"info"}},[_vm._v("fal fa-check-circle")])],1):(status === 'X')?_c('v-list-item-subtitle',[_vm._v(" No Chapel Record Found "),_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fas fa-exclamation-circle")])],1):(status === 'R')?_c('v-list-item-subtitle',[_vm._v(" Credit Removed "),_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-minus-circle")])],1):_vm._e()],1),(status === 'Y' || status === 'A' || status === 'R')?_c('v-list-item-action',[(status === 'Y' || status === 'A')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeCredit(pidm)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")])],1):(status === 'R')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addCredit(pidm)}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fal fa-plus-circle")])],1):_vm._e()],1):_vm._e()],1)}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }